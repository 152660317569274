import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import { setPoliciesFilter } from 'features/settings/settingsSlice';

const Filter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const showActive = useSelector(
    (state) => state.settings.policies.filter.showActive
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div className="me-1">
        <IconButton
          color="primary"
          aria-label="more options"
          id="policy-filter-options"
          aria-controls={open ? 'policy-filter-options' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FilterListIcon />
        </IconButton>

        <Menu
          id="policy-filter-options"
          aria-labelledby="policy-filter-options"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch(setPoliciesFilter(null));
            }}
          >
            All
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch(setPoliciesFilter(true));
            }}
          >
            Active
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch(setPoliciesFilter(false));
            }}
          >
            Expired
          </MenuItem>
        </Menu>
      </div>
      <div>
        {showActive === true
          ? 'Active'
          : showActive === false
          ? 'Expired'
          : 'All'}
      </div>
    </>
  );
};

export default Filter;
