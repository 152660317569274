import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NewPolicy = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = (policyType) => {
    setAnchorEl(null);
    // to avoid redirect on when user clicks outside and return an event object instead;
    if (typeof policyType === 'string') {
      navigate(`${policyType}/new`);
    }
  };

  return (
    <>
      <Button
        id="new-policy-options"
        aria-controls={open ? 'new-policy-options' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
      >
        New Policy
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose('auto');
          }}
        >
          Auto Policy
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose('storage');
          }}
        >
          Storage Policy
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose('home');
          }}
        >
          Home Policy
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose('visitor');
          }}
        >
          Visitors Policy
        </MenuItem>
      </Menu>
    </>
  );
};

export default NewPolicy;
