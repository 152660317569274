export const pending = (
  state,
  {
    meta: {
      arg: { primaryContact }
    }
  }
) => {
  if (state?.data?.contacts?.[primaryContact]) {
    state.data.contacts[primaryContact].policies.status = 'loading';
    state.data.contacts[primaryContact].policies.error = null;
  }
};

export const fulfilled = (
  state,
  {
    meta: {
      arg: { primaryContact, transactionType, prevPolicy }
    },
    payload
  }
) => {
  if (state?.data?.contacts?.[primaryContact]) {
    state.data.contacts[primaryContact].policies.status = 'succeeded';
    state.data.contacts[primaryContact].policies.error = null;

    state.data.policies[payload.id] = payload;
    state.data.contacts[primaryContact].policies.list = [
      payload.id,
      ...state.data.contacts[primaryContact].policies.list
    ];

    // if renew, update (alter/add); mark the prevPolicy inactive
    if (
      (transactionType === 'renew' || transactionType === 'update') &&
      state.data.policies[prevPolicy]
    ) {
      state.data.policies[prevPolicy].isActive = false;
    }
  }
};

export const rejected = (
  state,
  {
    meta: {
      arg: { primaryContact }
    },
    error
  }
) => {
  if (state?.data?.contacts?.[primaryContact]) {
    state.data.contacts[primaryContact].policies.status = 'failed';
    state.data.contacts[primaryContact].policies.error = error.message;
  }
};
