import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { setShowSearch } from 'features/settings/settingsSlice';
import { Filter } from './components';

const Header = () => {
  const dispatch = useDispatch();
  const showSearch = useSelector((state) => state.settings.general.showSearch);

  return (
    <div className="sm:flex flex-wrap mb-6">
      <div className="flex items-center">
        <h1 className="text-2xl font-bold">Contacts</h1>
        <Filter />
        {!showSearch && (
          <div className="ms-2">
            <IconButton
              color="inherit"
              onClick={() => {
                dispatch(setShowSearch(!showSearch));
              }}
              aria-label="Show Search Bar"
            >
              <SearchIcon />
            </IconButton>
          </div>
        )}
      </div>

      <div className="ms-auto">
        <Button
          color="primary"
          component={RouterLink}
          to="./add"
          variant="contained"
        >
          New Contact
        </Button>
      </div>
    </div>
  );
};

export default Header;
