import React from 'react';

const Contact = () => (
  <div className="p-4">
    <h1 className="text-2xl font-bold mb-6">Contact Us</h1>
    <h5 className="font-bold">Code Pixel</h5>
    <p className="mb-2">(Harry Ghuman)</p>
    <p>Email: info@codepixel.ca</p>
    <p>Phone: (604) 323-4567</p>
  </div>
);

export default Contact;
