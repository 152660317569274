import { normalize } from 'normalizr';
import contactSchema from '../contactSchema';

export const pending = (
  state,
  {
    meta: {
      arg: { contactId, policyId }
    }
  }
) => {
  if (contactId && state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].attachmentsStatus = 'loading';
    state.data.contacts[contactId].attachmentsError = null;
  }
};

export const fulfilled = (
  state,
  {
    meta: {
      arg: { contactId, policyId }
    },
    payload
  }
) => {
  if (contactId && state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].attachmentsStatus = 'succeeded';
    state.data.contacts[contactId].attachmentsError = null;

    const normalizedContact = normalize(payload, contactSchema);
    state.data.contacts[contactId] =
      normalizedContact.entities.contacts[contactId];
  }
};

export const rejected = (
  state,
  {
    meta: {
      arg: { contactId, policyId }
    },
    error
  }
) => {
  if (contactId && state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].attachmentsStatus = 'failed';
    state.data.contacts[contactId].attachmentsError = error.message;
  }
};
