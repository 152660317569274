import React from 'react';
import { Form } from 'formik';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useBrokers } from 'hooks';
import { startOfDay } from 'date-fns';

const PolicyForm = ({
  errors,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  touched,
  values
}) => {
  const { brokers, list: brokersList } = useBrokers();

  return (
    <Form>
      <div className="mb-4">
        <TextField
          //defaultValue={values.broker}
          value={values.broker}
          error={errors.broker && touched.broker}
          helperText={errors.broker && touched.broker && errors.broker}
          label="Broker *"
          name="broker"
          onBlur={handleBlur}
          onChange={handleChange}
          select
          SelectProps={{ native: true }}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        >
          <option key="0" value="">
            select
          </option>
          {brokersList ? (
            <>
              {brokersList.map((key) => {
                return (
                  <option key={key} value={key}>
                    {brokers[key].brokerName}
                  </option>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </TextField>
      </div>

      <div className="mb-4">
        <DatePicker
          autoOk
          error={errors.createdAt && touched.createdAt}
          format="dd-MM-yyyy"
          helperText={errors.createdAt && touched.createdAt && errors.createdAt}
          inputVariant="outlined"
          InputLabelProps={{ shrink: !!values.createdAt }}
          label="Created On *"
          name="createdAt"
          onBlur={handleBlur}
          onChange={(d) => {
            setFieldValue('createdAt', startOfDay(d));
          }}
          value={values.createdAt}
          variant="inline"
        />
      </div>

      <div className="md:flex">
        <div className="w-40 mb-4 me-1">
          <DatePicker
            autoOk
            error={errors.coverageFrom && touched.coverageFrom}
            format="dd-MM-yyyy"
            helperText={
              errors.coverageFrom && touched.coverageFrom && errors.coverageFrom
            }
            inputVariant="outlined"
            label="Effective From *"
            name="coverageFrom"
            onBlur={handleBlur}
            onChange={(d) => {
              setFieldValue('coverageFrom', startOfDay(d));
            }}
            value={values.coverageFrom}
            variant="inline"
          />
        </div>

        <div className="w-40 mb-4">
          <DatePicker
            autoOk
            error={errors.coverageUpTo && touched.coverageUpTo}
            format="dd-MM-yyyy"
            helperText={
              errors.coverageUpTo && touched.coverageUpTo && errors.coverageUpTo
            }
            inputVariant="outlined"
            label="Valid Upto *"
            name="coverageUpTo"
            onBlur={handleBlur}
            onChange={(d) => {
              setFieldValue('coverageUpTo', startOfDay(d));
            }}
            style={{
              marginLeft: 10
            }}
            value={values.coverageUpTo}
            variant="inline"
          />
        </div>
      </div>
      {/* coverage */}

      {(values.policyType === 'auto' || values.policyType === 'storage') && (
        <div className="md:flex">
          <div className="w-32 mb-4 me-1">
            <TextField
              defaultValue={values.make}
              error={errors.make && touched.make}
              helperText={errors.make && touched.make && errors.make}
              label="Make *"
              name="make"
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="w-32 mb-4 me-1">
            <TextField
              defaultValue={values.model}
              error={errors.model && touched.model}
              helperText={errors.model && touched.model && errors.model}
              label="Model *"
              name="model"
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="w-32 mb-4">
            <TextField
              defaultValue={values.year}
              error={errors.year && touched.year}
              helperText={errors.year && touched.year && errors.year}
              label="Year *"
              name="year"
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
      )}
      {/* auto/storage policy */}

      {values.policyType === 'auto' && (
        <div className="w-32 mb-4">
          <TextField
            defaultValue={values.plateNo}
            error={errors.plateNo && touched.plateNo}
            helperText={errors.plateNo && touched.plateNo && errors.plateNo}
            label="Plate *"
            name="plateNo"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
      )}
      {/* (only) auto policy */}

      {(values.policyType === 'home' || values.policyType === 'visitor') && (
        <div className="mb-4">
          <TextField
            defaultValue={values.insurer}
            error={errors.insurer && touched.insurer}
            helperText={errors.insurer && touched.insurer && errors.insurer}
            label="Insurer *"
            name="insurer"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
          />
        </div>
      )}

      {values.policyType === 'home' && (
        <div className="w-80 mb-4">
          <TextField
            defaultValue={values.address}
            error={errors.address && touched.address}
            helperText={errors.address && touched.address && errors.address}
            label="Address *"
            name="address"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
      )}

      {values.policyType === 'visitor' && (
        <div className="w-48 mb-4">
          <TextField
            defaultValue={values.insured}
            error={errors.insured && touched.insured}
            helperText={errors.insured && touched.insured && errors.insured}
            label="Insured *"
            name="insured"
            onBlur={handleBlur}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>
      )}

      <div className="w-full md:w-80 mb-6">
        <TextField
          defaultValue={values.notes}
          error={errors.notes && touched.notes}
          helperText={errors.notes && touched.notes && errors.notes}
          label="Notes"
          name="notes"
          multiline
          minRows={2}
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <p className="my-2 text-sm text-slate-500">
          <span className="text-green-500">HIGHLY RECOMMENDED:</span> Keep notes
          to recall what happened during the transaction. Anything that could
          help you in the future.
        </p>
      </div>

      <Button
        color="primary"
        disabled={isSubmitting}
        disableElevation
        type="submit"
        variant="contained"
      >
        {isSubmitting ? 'Saving' : 'Submit'}
      </Button>
    </Form>
  );
};

export default PolicyForm;
