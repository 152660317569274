import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { Attachments } from 'components';

const ContactAttachments = () => {
  const { contactId } = useParams();
  const [showForm, setShowForm] = useState(false);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="p-4">
      <div className="flex flex-wrap justify-between items-center mb-6">
        <div className="flex items-center">
          <div className="me-2">
            <IconButton component={Link} to={`../policies`}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <h1 className="text-2xl font-bold">Contact Attachments</h1>
        </div>
        {!showForm && (
          <Button
            variant="contained"
            onClick={(e) => {
              setShowForm(true);
            }}
          >
            New Attachment
          </Button>
        )}
      </div>
      {/* title */}

      <Attachments
        contactId={contactId}
        handleCloseForm={handleCloseForm}
        showForm={showForm}
      />
    </div>
  );
};

export default ContactAttachments;
