export const pending = (state) => {
  state.status = 'loading';
  state.error = null;
};

export const fulfilled = (state, action) => {
  state.status = 'succeeded';
  state.error = null;
  state.data.contacts[action.payload.id] = action.payload;
  state.list.unshift(action.payload.id);
};

export const rejected = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};
