import React, { useEffect } from 'react';
import Dashboard from 'layouts/Dashboard';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent
} from '@mui/material';

const brokerSchema = Yup.object().shape({
  brokerName: Yup.string().required(),
  address: Yup.string().nullable(),
  city: Yup.string().nullable(),
  postalCode: Yup.string().length(6).nullable(),
  phoneNo: Yup.string()
    .matches(/^[0-9]{10}$/, {
      message: 'Must be 10 digit number only',
      excludeEmptyString: false
    })
    .required('Cell# is required'),
  email: Yup.string().email('Invalid email').required()
});

const BrokerManage = ({
  brokerId,
  brokerReducer,
  createBroker,
  history,
  loadBrokers
}) => {
  useEffect(() => {
    if (brokerReducer.list === null) {
      loadBrokers().catch((error) => {
        console.log(error);
        toast.error('Error: could not load brokers.');
      });
    }
  }, [brokerReducer.list, loadBrokers]);

  const getBrokerById = (brokerId) => {
    return brokerReducer.broker && brokerReducer.brokers[brokerId]
      ? brokerReducer.brokers[brokerId]
      : {};
  };

  return (
    <Dashboard>
      <div>
        <Typography variant="h2">
          {brokerId ? 'Update ' : 'New '} Contact
        </Typography>
      </div>
      <Formik
        initialValues={{
          brokerName: '',
          address: '',
          city: '',
          postalCode: '',
          phoneNo: '',
          email: '',
          ...getBrokerById(brokerId)
        }}
        onSubmit={(values, { setSubmitting }) => {
          // same shape as initial values
          createBroker(values)
            .then(() => {
              setSubmitting(false);
              toast.success(
                `${values.brokerName} ${values.id ? 'updated' : 'created'}`
              );
              history.push('/brokers');
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
              toast.error('Error creating broker');
            });
        }}
        validationSchema={brokerSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          values
        }) => (
          <Form>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      defaultValue={values.brokerName}
                      error={errors.brokerName && touched.brokerName}
                      helperText={
                        errors.brokerName &&
                        touched.brokerName &&
                        errors.brokerName
                      }
                      label="Broker Company"
                      name="brokerName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Typography variant="h4">Contact Details</Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      defaultValue={values.address}
                      error={errors.address && touched.address}
                      helperText={
                        errors.address && touched.address && errors.address
                      }
                      label="Address"
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      defaultValue={values.city}
                      error={errors.city && touched.city}
                      helperText={errors.city && touched.city && errors.city}
                      label="City"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      defaultValue={values.postalCode}
                      error={errors.postalCode && touched.postalCode}
                      helperText={
                        errors.postalCode &&
                        touched.postalCode &&
                        errors.postalCode
                      }
                      label="Postal Code"
                      name="postalCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      defaultValue={values.email}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      label="Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <TextField
                      defaultValue={values.phoneNo}
                      error={errors.phoneNo && touched.phoneNo}
                      helperText={
                        errors.phoneNo && touched.phoneNo && errors.phoneNo
                      }
                      label="Phone#"
                      name="phoneNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Button
              color="primary"
              disabled={isSubmitting}
              disableElevation
              type="submit"
              variant="contained"
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </Form>
        )}
      </Formik>
    </Dashboard>
  );
};

export default BrokerManage;
