export const pending = (state, action) => {
  // we also need the current contact Id; to update this;
  const { prevContactId, contactId } = action.meta.arg;
  if (state?.data?.contacts?.[prevContactId]) {
    state.data.contacts[prevContactId].policies.status = 'loading';
    state.data.contacts[prevContactId].policies.error = null;
  }
};

export const fulfilled = (
  state,
  {
    meta: {
      arg: { prevContactId, contactId }
    },
    payload
  }
) => {
  if (state?.data?.contacts?.[prevContactId]) {
    // update policy itself; for updated contacts & primaryContact
    state.data.policies[payload._id] = payload;

    // temp: reset it
    /*state.data.contacts[prevContactId].policies.status = 'idle';
    state.data.contacts[prevContactId].policies.error = null;
    state.data.contacts[prevContactId].policies.list = [];*/

    state.data.contacts[prevContactId].policies.status = 'succeeded';
    state.data.contacts[prevContactId].policies.error = null;

    // remove policy from prevContacts policies.list;
    // having trouble with this; will check later;
    state.data.contacts[prevContactId].policies.list = [
      ...state.data.contacts[prevContactId].policies.list.filter(
        (id) => String(id) !== String(payload._id)
      )
    ];
  }

  // if present; update new contacts policies.list;
  if (state?.data?.contacts?.[contactId]?.policies?.list) {
    state.data.contacts[contactId].policies.list = [
      ...state.data.contacts[contactId].policies.list,
      payload._id
    ];
  }
};

export const rejected = (
  state,
  {
    meta: {
      arg: { prevContactId }
    },
    error
  }
) => {
  if (state?.data?.contacts?.[prevContactId]) {
    state.data.contacts[prevContactId].policies.status = 'failed';
    state.data.contacts[prevContactId].policies.error = error.message;
  }
};
