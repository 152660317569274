export const pending = (
  state,
  {
    meta: {
      arg: { contactId, policyId }
    }
  }
) => {
  if (contactId && state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].attachmentsStatus = 'loading';
    state.data.contacts[contactId].attachmentsError = null;
  }

  // to-do for policies;
};

export const fulfilled = (state, action) => {
  const {
    meta: { arg: formData },
    payload
  } = action;

  if (formData) {
    const policyId = formData.get('policyId');
    const contactId = formData.get('contactId');

    if (
      policyId &&
      state.data.policies[policyId]?.attachments &&
      payload?.attachment?.id
    ) {
      state.data.policies[policyId].attachments.push(payload.attachment.id);
    }

    if (
      contactId &&
      state.data.contacts[contactId]?.attachments &&
      payload?.attachment?.id
    ) {
      state.data.contacts[contactId].attachmentsStatus = 'succeeded';
      state.data.contacts[contactId].attachmentsError = null;

      state.data.contacts[contactId].attachments.push(payload.attachment.id);
    }
  }
};

export const rejected = (
  state,
  {
    meta: {
      arg: { contactId, policyId }
    },
    error
  }
) => {
  if (contactId && state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].attachmentsStatus = 'failed';
    state.data.contacts[contactId].attachmentsError = error.message;
  }

  // to-do for policies;
};
