import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Attachments } from 'components';

const PolicyAttachments = () => {
  const { policyId } = useParams();
  const navigate = useNavigate();
  const policies = useSelector((state) => state.contacts.data.policies);
  const [showForm, setShowForm] = useState(false);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    if (!policies[policyId]) {
      //if not present go back to policies;
      navigate(`../..`);
    }
  }, [policyId]);

  return (
    <div className="p-4">
      <div className="flex flex-wrap justify-between items-center mb-6">
        <div className="flex items-center">
          <div className="me-2">
            <IconButton component={Link} to={`../..`}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <h1 className="text-2xl font-bold">Policy Attachments</h1>
        </div>
        {!showForm && (
          <Button
            variant="contained"
            onClick={(e) => {
              setShowForm(true);
            }}
          >
            New Attachment
          </Button>
        )}
      </div>
      {/* title */}

      <Attachments
        policyId={policyId}
        handleCloseForm={handleCloseForm}
        showForm={showForm}
      />
    </div>
  );
};

export default PolicyAttachments;
