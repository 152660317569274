/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const config = [
  {
    title: 'Contacts',
    href: '/dashboard/contacts',
    icon: GroupIcon
  },

  {
    title: 'Expiring',
    href: '/dashboard/expiring',
    icon: AccessTimeFilledIcon
  },
  {
    title: 'Reports',
    href: '/dashboard/reports',
    icon: AssignmentIcon
  }
];

export default config;
