import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBrokers } from '../features/broker/brokerSlice';
import { toast } from 'react-toastify';

const useBrokers = () => {
  const dispatch = useDispatch();
  const {
    status,
    error,
    list,
    data: { brokers }
  } = useSelector((state) => state.brokers);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getBrokers())
        .unwrap()
        .then(() => {
          // doing nothing here;
        })
        .catch((error) =>
          toast.error(error.message || 'failed to load brokers')
        );
    }
  }, [status]);

  return { status, error, brokers, list };
};

export default useBrokers;
