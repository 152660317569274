import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, TextField } from '@mui/material';
import { registerUser } from 'api/userApi';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const { user, loginWithRedirect } = useAuth0();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      firstName: user?.given_name || '',
      lastName: user?.family_name || '',
      email: user?.email || '',
      company: '',
      cellNo: ''
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  // will use the below later;
  //const [error, setError] = useState(null);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    registerUser({ ...data, authId: user?.sub || '' })
      .then(() => {
        // lets refresh the user/token
        loginWithRedirect()
          .then(() => navigate('/dashboard/contacts'))
          .catch((err) => console.log(err));
      })
      .catch(console.error)
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="min-w-80 max-w-md bg-white rounded-lg p-4 md:p-6">
      <h1 className="text-2xl font-bold">Profile</h1>
      {/*<pre>{JSON.stringify(user, 2, null)}</pre>*/}
      <p className="my-6 text-slate-500">
        Fields marked with (*) are required.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-5">
          <div className="grid sm:grid-cols-2 gap-2">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Firstname *"
                  variant="outlined"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{
                required: 'Firstname is required'
              }}
            />

            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Lastname *"
                  variant="outlined"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{
                required: 'Lastname is required'
              }}
            />
          </div>
        </div>

        <div className="mb-5">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Email *"
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Enter a valid email address'
              }
            }}
          />
        </div>

        <div className="mb-5">
          <Controller
            name="cellNo"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Phone *"
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Phone is required',
              pattern: {
                value: /^\d{10}$/,
                message: 'Enter a 10 digit phone number'
              }
            }}
          />
        </div>

        <div className="mb-5">
          <Controller
            name="company"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Company"
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </div>

        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Register;
