import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Hidden,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar
} from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { setShowSearch } from 'features/settings/settingsSlice';
import { useAuth0 } from '@auth0/auth0-react';

const TopBar = ({ setNavOpen, navOpen, topBarRef }) => {
  const dispatch = useDispatch();
  const showSearch = useSelector((state) => state.settings.general.showSearch);
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="bg-blue-800 text-white fixed w-full top-0 z-10"
      ref={topBarRef}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={() => setNavOpen(!navOpen)}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Link to="/">Ins. CRM</Link>
        <div className="ms-auto">
          <IconButton
            color="inherit"
            onClick={() => {
              dispatch(setShowSearch(!showSearch));
            }}
            aria-label="Show Search Bar"
          >
            <SearchIcon />
          </IconButton>
          <IconButton color="inherit" href="/brokers" aria-label="Plates">
            <SettingsIcon />
          </IconButton>
          <IconButton onClick={handleProfileMenuClick}>
            <Avatar alt={user?.name || ''} src={user?.picture} />
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleProfileMenuClose}
          >
            <MenuItem
              style={{
                cursor: 'default',
                pointerEvents: 'none',
                color: 'gray',
                backgroundColor: 'transparent'
              }}
            >
              <ListItemText>
                <div className="text-sm">{user?.email || ''}</div>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                logout({
                  logoutParams: { returnTo: window.location.origin + '/logout' }
                });
                handleProfileMenuClose(e);
              }}
            >
              Log out
            </MenuItem>
          </Menu>
          {/*<Button
            color="inherit"
            onClick={() => {
              logout({
                logoutParams: { returnTo: window.location.origin + '/logout' }
              });
            }}
          >
            <InputIcon />
            Log out
          </Button>*/}
        </div>
      </Toolbar>
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  navOpen: PropTypes.bool,
  setNavOpen: PropTypes.func.isRequired
};

export default TopBar;
