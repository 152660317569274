import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Auth, Dashboard, Members, Contact as ContactLayout } from './layouts';
import { Routes, Route } from 'react-router-dom';
import { history, Router } from './history';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Brokers,
  BrokerManage,
  Login,
  Logout,
  Register,
  ContactAttachments,
  ContactManage,
  Contacts,
  PolicyManage,
  PolicyCancel,
  PolicyAttachments,
  Policies,
  Expiring,
  Reports,
  Unauthorized,
  PageNotFound,
  TermsOfService,
  PrivacyPolicy,
  Contact as ContactPage
} from './views';
import store from './app/store';

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin
        }}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <Router history={history}>
              <Routes>
                <Route path="members" element={<Members />}>
                  <Route path="register" element={<Register />} />
                </Route>
                <Route path="dashboard" element={<Dashboard />}>
                  <Route path="brokers">
                    <Route index element={<Brokers />} />
                    <Route path="manage">
                      <Route index element={<BrokerManage />} />
                      <Route path=":id" element={<BrokerManage />} />
                    </Route>
                  </Route>

                  <Route path="contacts">
                    <Route index element={<Contacts />} />
                    <Route path="add">
                      <Route index element={<ContactManage />} />
                    </Route>
                    <Route path=":contactId" element={<ContactLayout />}>
                      <Route path="update" element={<ContactManage />} />
                      <Route
                        path="attachments"
                        element={<ContactAttachments />}
                      />
                      <Route path="policies">
                        <Route index element={<Policies />} />
                        {/* policyTYpe: auto, storage, home, visitor,*/}
                        <Route path=":policyType">
                          <Route path=":transactionType">
                            <Route index element={<PolicyManage />} />
                            <Route
                              path=":policyId"
                              element={<PolicyManage />}
                            />
                          </Route>
                        </Route>
                        <Route path=":policyId">
                          <Route path="cancel" element={<PolicyCancel />} />
                          <Route
                            path="attachments"
                            element={<PolicyAttachments />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path="reports" element={<Reports />} />
                  <Route path="expiring" element={<Expiring />} />
                </Route>
                <Route path="/" element={<Auth />}>
                  <Route index element={<Login />} />
                  <Route path="login" element={<Login />} />
                  <Route path="logout" element={<Logout />} />
                  <Route path="unauthorized" element={<Unauthorized />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="terms-of-service" element={<TermsOfService />} />
                  <Route path="contact" element={<ContactPage />} />
                  <Route path="*" element={<PageNotFound />} />
                </Route>
              </Routes>
            </Router>
          </ThemeProvider>
        </Provider>
      </Auth0Provider>
    </LocalizationProvider>
  );
};

export default App;
