export const pending = (
  state,
  {
    meta: {
      arg: { contactId }
    }
  }
) => {
  if (state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].policies.status = 'loading';
    state.data.contacts[contactId].policies.error = null;
  }
};

export const fulfilled = (
  state,
  {
    meta: {
      arg: { contactId, policyId }
    },
    payload
  }
) => {
  if (state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].policies.status = 'succeeded';
    state.data.contacts[contactId].policies.error = null;
    state.data.contacts[contactId].policies.list = state.data.contacts[
      contactId
    ].policies.list.filter((item) => item !== policyId);

    delete state.data.policies[payload.id];
  }
};

export const rejected = (
  state,
  {
    meta: {
      arg: { contactId }
    },
    error
  }
) => {
  if (state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].policies.status = 'failed';
    console.error(error);
    state.data.contacts[contactId].policies.error = error.message;
  }
};
