import { normalize } from 'normalizr';
import brokerSchema from '../brokerSchema';

export const pending = (state) => {
  state.status = 'loading';
  state.error = null;
};

export const fulfilled = (state, action) => {
  state.status = 'succeeded';
  state.error = null;
  if (action.payload.length) {
    const normalizedData = normalize(action.payload, [brokerSchema]);

    state.data.brokers = normalizedData.entities.brokers;
    state.list = normalizedData.result;
  }
};

export const rejected = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};
