import React from 'react';

const NewlineToBr = ({ text }) => {
  // Split the text by newline character (\n)
  const lines = text.split('\n');

  return (
    <>
      {/* Map over the array of lines and render each line within a <p> tag */}
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};

export default NewlineToBr;
