import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContacts } from '../features/contact/contactSlice';
import { toast } from 'react-toastify';

const useContacts = () => {
  const dispatch = useDispatch();
  const {
    status,
    error,
    list,
    data: { contacts }
  } = useSelector((state) => state.contacts);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getContacts())
        .unwrap()
        .then(() => {
          // doing nothing here;
        })
        .catch((error) =>
          toast.error(error.message || 'failed to load contacts')
        );
    }
  }, [status]);

  return { status, error, contacts, list };
};

export default useContacts;
