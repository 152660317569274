export const pending = (state) => {
  state.status = 'loading';
  state.error = null;
};

export const fulfilled = (state, action) => {
  state.status = 'succeeded';
  state.error = null;
  state.list = state.list.filter((item) => item !== action.meta.arg.contactId);
};

export const rejected = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};
