import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as brokersApi from '../../api/brokersApi';
import { getBrokersReducer } from './reducers';

export const getBrokers = createAsyncThunk(
  'brokers/getBrokers',
  async (params) => await brokersApi.getBrokers(params)
);

/*export const addBroker = createAsyncThunk(
  'brokers/addBroker',
  async (params) => await contactApi.saveContact(params)
);

export const updateBroker = createAsyncThunk(
  'brokers/updateBroker',
  async (params) => await contactApi.saveContact(params)
);

export const getPolicies = createAsyncThunk(
  'brokers/fetchPolicies',
  async (params) => await brokersApi.getPolicies(params)
);*/

export const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    data: { brokers: {} },
    list: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBrokers.pending, getBrokersReducer.pending)
      .addCase(getBrokers.fulfilled, getBrokersReducer.fulfilled)
      .addCase(getBrokers.rejected, getBrokersReducer.rejected);

    /*.addCase(addBroker.pending, addBrokerReducer.pending)
      .addCase(addBroker.fulfilled, addBrokerReducer.fulfilled)
      .addCase(addBroker.rejected, addBrokerReducer.rejected)

      .addCase(updateBroker.pending, updateBrokerReducer.pending)
      .addCase(updateBroker.fulfilled, updateBrokerReducer.fulfilled)
      .addCase(updateBroker.rejected, updateBrokerReducer.rejected)

      .addCase(getPolicies.pending, getPoliciesReducer.pending)
      .addCase(getPolicies.fulfilled, getPoliciesReducer.fulfilled)
      .addCase(getPolicies.rejected, getPoliciesReducer.rejected)*/
  }
});

export default contactSlice.reducer;
