import React, { useState, useEffect } from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { TextField, IconButton } from '@mui/material';
import {
  useSearchParams,
  useNavigate,
  createSearchParams
} from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from 'react-redux';
import { setShowSearch } from 'features/settings/settingsSlice';

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  let [searchParams] = useSearchParams();
  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(setShowSearch(false));

    if (searchTerm.trim() === '') {
      navigate('/dashboard/contacts');
    } else {
      navigate({
        pathname: '/dashboard/contacts',
        search: createSearchParams({
          search: searchTerm
        }).toString()
      });
    }
  };

  useEffect(() => {
    setSearchTerm(searchParams.get('search'));
  }, []);

  return (
    <form className="flex items-center p-4" onSubmit={handleSubmit}>
      <TextField
        name="search"
        placeholder="Search contacts"
        value={searchTerm || ''}
        fullWidth
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <>
              {searchTerm && (
                <IconButton
                  onClick={() => {
                    setSearchTerm('');
                    navigate('/dashboard/contacts');
                    //dispatch(setShowSearch(false));
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton type="submit">
                <SearchIcon />
              </IconButton>
            </>
          )
        }}
      />
    </form>
  );
};

export default Search;
