import { schema } from 'normalizr';

const policyProcessStrategy = (value) => {
  return {
    ...value,
    attachmentsStatus: 'succeeded',
    attachmentsError: null
  };
};

const policiesSchema = new schema.Entity(
  'policies',
  {},
  { idAttribute: '_id', processStrategy: policyProcessStrategy }
);

export default policiesSchema;
