const getPolicyInsured = (
  policyType,
  { make, model, year, address, insured, plateNo }
) => {
  if (policyType === 'auto') {
    return `${make || ''} ${model || ''} ${year || ''} - ${plateNo || ''}`;
  } else if (policyType === 'storage') {
    return `${make || ''} ${model || ''} ${year || ''}`;
  } else if (policyType === 'home') {
    return address;
  } else if (policyType === 'visitor') {
    return insured;
  } else {
    return null;
  }
};

export default getPolicyInsured;
