import React, { useEffect, useState } from 'react';
import { getAttachmentThumb, getAttachmentImage } from 'api/attachmentsApi';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import { useDispatch } from 'react-redux';
import {
  deleteContactAttachment,
  deletePolicyAttachment,
  moveAttachmentToContact
} from 'features/contact/contactSlice';
import { toast } from 'react-toastify';

const AttachmentImage = ({ id, policyId, contactId }) => {
  const dispatch = useDispatch();
  const [thumbBlob, setThumbBlob] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [showImage, setShowImage] = useState(false);

  const getImage = async () => {
    if (!imageBlob) {
      try {
        const response = await getAttachmentImage(id);
        setImageBlob(response);
      } catch (error) {
        setImageBlob(new Blob());
        console.error('Error fetching image:', error);
      }
    }
  };

  useEffect(() => {
    const getThumb = async () => {
      try {
        const response = await getAttachmentThumb(id);
        setThumbBlob(response);
      } catch (error) {
        setThumbBlob(new Blob());
        console.error('Error fetching thumb:', error);
      }
    };

    getThumb();
  }, []);

  return (
    <a
      href="#"
      onClick={() => {
        setShowImage(true);
        getImage();
      }}
    >
      {thumbBlob && (
        <div className="relative">
          <img src={URL.createObjectURL(thumbBlob)} />
          <div className="absolute top-0 right-0 m-2 z-10">
            <div className="flex">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (contactId) {
                    dispatch(deleteContactAttachment({ id, contactId }));
                  } else if (policyId) {
                    dispatch(deletePolicyAttachment({ id, policyId }));
                  } else {
                    console.error('missing required query params');
                  }
                }}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </IconButton>
              {policyId && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(moveAttachmentToContact({ id, policyId }))
                      .then(() => {
                        toast.success(
                          'Attachment Moved, please refresh to review.'
                        );
                      })
                      .catch((err) => {
                        console.error(err);
                        toast.error('Failed to move the attachment');
                      });
                  }}
                >
                  <BurstModeIcon style={{ color: 'white' }} />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      )}
      {showImage && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black z-50 bg-opacity-50"
          onClick={(e) => {
            e.stopPropagation();
            setShowImage(false);
          }}
        >
          <div className="relative text-white">
            {imageBlob ? (
              <img src={URL.createObjectURL(imageBlob)} />
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      )}
    </a>
  );
};

export default AttachmentImage;
