import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { startOfDay, subDays, addDays, addYears, parseISO } from 'date-fns';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPolicy,
  fixPolicy,
  getPolicies
} from 'features/contact/contactSlice';
import { PolicyForm } from 'components';
import policySchema from './policySchema';
import { useContacts } from 'hooks';
import { Spinner } from 'components';

const PolicyManage = () => {
  const dispatch = useDispatch();
  const { contactId, policyType, transactionType, policyId } = useParams();
  const navigate = useNavigate();
  const defaultValues = {
    policyType, //required to refresh the formik; without this it doesn't function as expected.
    broker: '',
    createdAt: startOfDay(new Date()),
    coverageFrom: startOfDay(new Date()),
    coverageUpTo: subDays(addYears(startOfDay(new Date()), 1), 1),
    notes: ''
  };
  const defaultValuesByCategory = {
    auto: {
      ...defaultValues,
      make: '',
      model: '',
      year: '',
      plateNo: ''
    },
    storage: {
      ...defaultValues,
      make: '',
      model: '',
      year: ''
    },
    home: {
      ...defaultValues,
      insurer: '',
      address: ''
    },
    visitor: {
      ...defaultValues,
      insurer: '',
      insured: ''
    }
  };
  const { status: contactsStatus, contacts } = useContacts();
  const [initialValues, setInitialValues] = useState(
    defaultValuesByCategory[policyType]
  );
  const policies = useSelector((state) => state.contacts.data.policies);

  useEffect(() => {
    if (transactionType !== 'new') {
      // make sure we have:
      // 1. contacts loaded
      // 2. if contact is loaded; make sure we have the policies loaded;
      if (contacts?.[contactId]?.policies?.status === 'idle') {
        dispatch(getPolicies(contactId));
      } else if (
        contacts?.[contactId]?.policies?.status === 'succeeded' &&
        policies?.[policyId]
      ) {
        // get the policy
        const policy = {
          policyType,
          broker: policies[policyId].broker,
          createdAt: startOfDay(parseISO(policies[policyId].createdAt)),
          coverageFrom: startOfDay(parseISO(policies[policyId].coverageFrom)),
          coverageUpTo: startOfDay(parseISO(policies[policyId].coverageUpTo)),
          notes: transactionType === 'fix' ? policies[policyId].notes : ''
        };

        // if its renew transaction
        if (transactionType === 'renew') {
          policy['coverageFrom'] = startOfDay(
            addDays(parseISO(policies[policyId].coverageUpTo), 1)
          );
          policy['coverageUpTo'] = startOfDay(
            addYears(parseISO(policies[policyId].coverageUpTo), 1)
          );
        }

        if (policyType === 'auto' || policyType === 'storage') {
          policy['make'] = policies[policyId].make;
          policy['model'] = policies[policyId].model;
          policy['year'] = policies[policyId].year;
        }

        if (policyType === 'auto') {
          policy['plateNo'] = policies[policyId].plateNo;
        }

        if (policyType === 'home' || policyType === 'visitor') {
          policy['insurer'] = policies[policyId].insurer;
        }

        if (policyType === 'home') {
          policy['address'] = policies[policyId].address;
        }

        if (policyType === 'visitor') {
          policy['insured'] = policies[policyId].insured;
        }

        setInitialValues(policy);
      } else {
        //if not present go back to policies;
        // in case of expiring reports, we do not necessarily have the policies;
        // we must get them here; instead of falling back;
        /*console.log(
          'policies are not loaded; and should load by itself using usePolicies hook'
        );*/
        //navigate(`/dashboard/contacts/${contactId}/policies`);
      }
    }
  }, [
    transactionType,
    contactsStatus,
    contacts?.[contactId]?.policies?.status === 'succeeded'
  ]);

  return (
    <div className="p-4">
      <div className="flex items-center mb-6">
        <div className="me-2">
          <IconButton
            component={Link}
            to={`/dashboard/contacts/${contactId}/policies`}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <h1 className="text-2xl font-bold">
          {transactionType} {policyType} policy
        </h1>
      </div>

      {transactionType !== 'new' && (
        <>
          {contactsStatus === 'loading' ||
            (contacts?.[contactId]?.policies?.status === 'loading' && (
              <Spinner />
            ))}
        </>
      )}

      {(transactionType === 'new' || policies?.[policyId]) && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            const data = {
              primaryContact: contactId,
              policyType,
              transactionType,
              ...values
            };

            if (transactionType === 'fix') {
              data['id'] = policyId;
            }

            if (
              (transactionType === 'renew' || transactionType === 'update') &&
              policyId
            ) {
              data['prevPolicy'] = policyId;
            }

            // also add the logic to fix the policy
            dispatch(
              transactionType === 'fix' ? fixPolicy(data) : addPolicy(data)
            )
              .unwrap()
              .then(() => {
                setSubmitting(false);
                toast.success('Policy successfully created.');
                navigate(`/dashboard/contacts/${contactId}/policies`);
              })
              .catch((error) => {
                console.log(error);
                setSubmitting(false);
                toast.error('There was an error creating the new policy');
              });
          }}
          validationSchema={policySchema}
        >
          {(props) => <PolicyForm {...props} />}
        </Formik>
      )}
    </div>
  );
};

export default PolicyManage;
