import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AttachmentImage } from 'components';
import { useContacts } from 'hooks';
import { addAttachment } from 'features/contact/contactSlice';
import { toast } from 'react-toastify';

const AttachmentForm = ({ handleCloseForm, policyId, contactId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', data.file[0]);
      if (policyId) {
        formData.append('policyId', policyId);
      }
      if (contactId) {
        formData.append('contactId', contactId);
      }

      dispatch(addAttachment(formData))
        .unwrap()
        .then(() => {
          toast.success(`File successfully attached`);
          handleCloseForm();
        })
        .catch((error) => {
          console.log(error);
          toast.error('Error attaching file');
        });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="border border-slate-100 p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Upload Image</h2>
        <Button onClick={handleCloseForm} variant="outlined">
          Close
        </Button>
      </div>
      {/* title */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="file"
          {...register('file', { required: 'File is required' })}
        />
        {errors.file && (
          <span className="text-red-500">{errors.file.message}</span>
        )}

        <Button type="submit" variant="contained">
          Upload
        </Button>
      </form>
    </div>
  );
};

const Attachments = ({ policyId, contactId, showForm, handleCloseForm }) => {
  const navigate = useNavigate();
  const policies = useSelector((state) => state.contacts.data.policies);
  const { contacts } = useContacts();

  useEffect(() => {
    if (policyId && !policies[policyId]) {
      //if not present go back to policies;
      navigate(`../..`);
    }
  }, [policyId]);

  return (
    <>
      {showForm && (
        <div className="mb-8">
          {policyId && (
            <>
              <AttachmentForm
                handleCloseForm={handleCloseForm}
                showForm={showForm}
                policyId={policyId}
              />
            </>
          )}

          {contactId && (
            <>
              <AttachmentForm
                handleCloseForm={handleCloseForm}
                showForm={showForm}
                contactId={contactId}
              />
            </>
          )}
        </div>
      )}

      {policyId && (
        <>
          {policies?.[policyId]?.attachments &&
          policies[policyId].attachments.length ? (
            <ul className="flex flex-wrap">
              {policies[policyId].attachments.map((attachmentId, idx) => (
                <li className="w-64 me-2 mb-2" key={idx}>
                  <AttachmentImage id={attachmentId} policyId={policyId} />
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-blue-600">0 attachments</p>
          )}
        </>
      )}

      {contactId && (
        <>
          {contacts?.[contactId]?.attachments &&
          contacts[contactId].attachments.length ? (
            <ul className="flex flex-wrap">
              {contacts[contactId].attachments.map((attachmentId, idx) => (
                <li className="w-64 me-2 mb-2" key={idx}>
                  <AttachmentImage id={attachmentId} contactId={contactId} />
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-blue-600">0 attachments</p>
          )}
        </>
      )}
    </>
  );
};

export default Attachments;
