import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as contactApi from '../../api/contactApi';
import * as policiesApi from '../../api/policiesApi';
import * as attachmentApi from '../../api/attachmentsApi';
import {
  getContactsReducer,
  addContactReducer,
  updateContactReducer,
  activateContactReducer,
  deleteContactReducer,
  getPoliciesReducer,
  addPolicyReducer,
  fixPolicyReducer,
  deletePolicyReducer,
  addAttachmentReducer,
  deleteContactAttachmentReducer,
  deletePolicyAttachmentReducer,
  moveAttachmentToContactReducer,
  movePolicyReducer
} from './reducers';

export const getContacts = createAsyncThunk(
  'contacts/fetchContacts',
  async (params) => await contactApi.getContacts(params)
);

export const addContact = createAsyncThunk(
  'contacts/addContact',
  async (params) => await contactApi.saveContact(params)
);

export const updateContact = createAsyncThunk(
  'contacts/updateContact',
  async (params) => await contactApi.saveContact(params)
);

export const activateContact = createAsyncThunk(
  'contacts/activateContact',
  async (params) => await contactApi.activateContact(params)
);

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async (params) => await contactApi.deleteContact(params)
);

export const getPolicies = createAsyncThunk(
  'contacts/fetchPolicies',
  async (params) => await policiesApi.getPolicies(params)
);

export const addPolicy = createAsyncThunk(
  'contacts/addPolicy',
  async (params) => await policiesApi.savePolicy(params)
);

export const fixPolicy = createAsyncThunk(
  'contacts/fixPolicy',
  async (params) => await policiesApi.fixPolicy(params)
);

export const cancelPolicy = createAsyncThunk(
  'contacts/cancelPolicy',
  async (params) => await policiesApi.cancelPolicy(params)
);

export const expirePolicy = createAsyncThunk(
  'contacts/expirePolicy',
  async (params) => await policiesApi.expirePolicy(params)
);

export const movePolicy = createAsyncThunk(
  'contacts/movePolicy',
  async (params) => await policiesApi.movePolicy(params)
);

export const deletePolicy = createAsyncThunk(
  'contacts/deletePolicy',
  async (params) => await policiesApi.deletePolicy(params)
);

export const addAttachment = createAsyncThunk(
  'contacts/addAttachment',
  async (params) => await attachmentApi.postAttachment(params)
);

export const moveAttachmentToContact = createAsyncThunk(
  'contacts/moveAttachmentToContact',
  async (params) => await attachmentApi.moveToContact(params)
);

export const deleteContactAttachment = createAsyncThunk(
  'contacts/deleteContactAttachment',
  async (params) => await attachmentApi.deleteAttachment(params)
);

export const deletePolicyAttachment = createAsyncThunk(
  'contacts/deletePolicyAttachment',
  async (params) => await attachmentApi.deleteAttachment(params)
);

export const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    data: { contacts: {}, policies: {} },
    list: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getContacts.pending, getContactsReducer.pending)
      .addCase(getContacts.fulfilled, getContactsReducer.fulfilled)
      .addCase(getContacts.rejected, getContactsReducer.rejected)

      .addCase(addContact.pending, addContactReducer.pending)
      .addCase(addContact.fulfilled, addContactReducer.fulfilled)
      .addCase(addContact.rejected, addContactReducer.rejected)

      .addCase(updateContact.pending, updateContactReducer.pending)
      .addCase(updateContact.fulfilled, updateContactReducer.fulfilled)
      .addCase(updateContact.rejected, updateContactReducer.rejected)

      .addCase(activateContact.pending, activateContactReducer.pending)
      .addCase(activateContact.fulfilled, activateContactReducer.fulfilled)
      .addCase(activateContact.rejected, activateContactReducer.rejected)

      .addCase(deleteContact.pending, deleteContactReducer.pending)
      .addCase(deleteContact.fulfilled, deleteContactReducer.fulfilled)
      .addCase(deleteContact.rejected, deleteContactReducer.rejected)

      .addCase(getPolicies.pending, getPoliciesReducer.pending)
      .addCase(getPolicies.fulfilled, getPoliciesReducer.fulfilled)
      .addCase(getPolicies.rejected, getPoliciesReducer.rejected)

      .addCase(addPolicy.pending, addPolicyReducer.pending)
      .addCase(addPolicy.fulfilled, addPolicyReducer.fulfilled)
      .addCase(addPolicy.rejected, addPolicyReducer.rejected)

      // fixPolicyReducer; is also used for - cancelPolicy, expirePolicy
      .addCase(fixPolicy.pending, fixPolicyReducer.pending)
      .addCase(fixPolicy.fulfilled, fixPolicyReducer.fulfilled)
      .addCase(fixPolicy.rejected, fixPolicyReducer.rejected)

      .addCase(cancelPolicy.pending, fixPolicyReducer.pending)
      .addCase(cancelPolicy.fulfilled, fixPolicyReducer.fulfilled)
      .addCase(cancelPolicy.rejected, fixPolicyReducer.rejected)

      .addCase(expirePolicy.pending, fixPolicyReducer.pending)
      .addCase(expirePolicy.fulfilled, fixPolicyReducer.fulfilled)
      .addCase(expirePolicy.rejected, fixPolicyReducer.rejected)

      .addCase(movePolicy.pending, movePolicyReducer.pending)
      .addCase(movePolicy.fulfilled, movePolicyReducer.fulfilled)
      .addCase(movePolicy.rejected, movePolicyReducer.rejected)

      .addCase(deletePolicy.pending, deletePolicyReducer.pending)
      .addCase(deletePolicy.fulfilled, deletePolicyReducer.fulfilled)
      .addCase(deletePolicy.rejected, deletePolicyReducer.rejected)

      .addCase(addAttachment.pending, addAttachmentReducer.pending)
      .addCase(addAttachment.fulfilled, addAttachmentReducer.fulfilled)
      .addCase(addAttachment.rejected, addAttachmentReducer.rejected)

      .addCase(
        moveAttachmentToContact.pending,
        moveAttachmentToContactReducer.pending
      )
      .addCase(
        moveAttachmentToContact.fulfilled,
        moveAttachmentToContactReducer.fulfilled
      )
      .addCase(
        moveAttachmentToContact.rejected,
        moveAttachmentToContactReducer.rejected
      )

      // this only works for Contacts addAttachments; not for Policy addAttachments;
      // because the api return payload is different in both cases;
      .addCase(
        deleteContactAttachment.pending,
        deleteContactAttachmentReducer.pending
      )
      .addCase(
        deleteContactAttachment.fulfilled,
        deleteContactAttachmentReducer.fulfilled
      )
      .addCase(
        deleteContactAttachment.rejected,
        deleteContactAttachmentReducer.rejected
      )

      .addCase(
        deletePolicyAttachment.pending,
        deletePolicyAttachmentReducer.pending
      )
      .addCase(
        deletePolicyAttachment.fulfilled,
        deletePolicyAttachmentReducer.fulfilled
      )
      .addCase(
        deletePolicyAttachment.rejected,
        deletePolicyAttachmentReducer.rejected
      );
  }
});

export default contactSlice.reducer;
