import { normalize } from 'normalizr';
import contactSchema from '../contactSchema';

export const pending = (state) => {
  state.status = 'loading';
  state.error = null;
};

export const fulfilled = (state, action) => {
  state.status = 'succeeded';
  state.error = null;
  if (action.payload.length) {
    const normalizedContacts = normalize(action.payload, [contactSchema]);

    state.data.contacts = normalizedContacts.entities.contacts;
    state.list = normalizedContacts.result;
  }
};

export const rejected = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};
