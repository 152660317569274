import { normalize } from 'normalizr';
import policySchema from '../policySchema';

export const pending = (
  state,
  {
    meta: {
      arg: { policyId }
    }
  }
) => {
  if (policyId && state?.data?.policies?.[policyId]) {
    state.data.policies[policyId].attachmentsStatus = 'loading';
    state.data.policies[policyId].attachmentsError = null;
  }
};

export const fulfilled = (
  state,
  {
    meta: {
      arg: { policyId }
    },
    payload
  }
) => {
  if (policyId && state?.data?.policies?.[policyId]) {
    state.data.policies[policyId].attachmentsStatus = 'succeeded';
    state.data.policies[policyId].attachmentsError = null;

    const normalizedContact = normalize(payload, policySchema);
    state.data.policies[policyId] =
      normalizedContact.entities.policies[policyId];
  }
};

export const rejected = (
  state,
  {
    meta: {
      arg: { policyId }
    },
    error
  }
) => {
  if (policyId && state?.data?.policies?.[policyId]) {
    state.data.policies[policyId].attachmentsStatus = 'failed';
    state.data.policies[policyId].attachmentsError = error.message;
  }
};
