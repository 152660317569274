import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FormatPhone } from 'components';
import {
  Card,
  CardContent,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

/*const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));*/

const List = ({ brokers, list }) => {
  return (
    <div>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {list.length} records found.
      </Typography>
      <Card>
        <CardContent>
          <PerfectScrollbar>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone#</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((key) => (
                    <TableRow hover key={key}>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/brokers/manage/${key}`}
                        >
                          {brokers[key].brokerName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <FormatPhone number={brokers[key].phoneNo} />
                      </TableCell>
                      <TableCell>{brokers[key].email}</TableCell>
                      <TableCell align="center">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={`/brokers/manage/${key}`}
                          variant="outlined"
                        >
                          update
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

List.propTypes = {
  brokers: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired
};

export default List;
