import React from 'react';
import { format, parseJSON } from 'date-fns';

const FormatDate = ({ date }) => {
  if (date) {
    return <>{format(parseJSON(date), 'MMM d, yyyy')}</>;
  } else {
    return <></>;
  }
};

export default FormatDate;
