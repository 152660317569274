import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const Unauthorized = () => {
  const { logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      logout({
        logoutParams: { returnTo: window.location.origin + '/unauthorized' }
      });
    }
  }, [isAuthenticated]);
  return (
    <div className="max-w-92 text-center">
      <h1 className="text-2xl font-bold">Access Denied</h1>
      <p className="mb-6">
        The requested resource is protected. You must login to continue.
      </p>
      <div>
        <Button component={Link} variant="contained" to="/login">
          Login
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
