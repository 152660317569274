import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <>
      <div className="text-center">
        <h1 className="text-2xl font-bold mb-4">Oops! page not found.</h1>
        <p className="mb-6">
          Sorry the page you are looking for does not exists.
        </p>

        <Button
          color="primary"
          component={Link}
          disableElevation
          to="/"
          variant="contained"
        >
          Back to Home
        </Button>
      </div>
    </>
  );
};

export default PageNotFound;
