import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const Logout = () => {
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      logout({
        logoutParams: { returnTo: window.location.origin + '/logout' }
      });
    }
  }, [isAuthenticated]);

  return (
    <div className="max-w-92 text-center">
      {isAuthenticated ? (
        <p>Please wait...</p>
      ) : (
        <>
          <h1 className="text-2xl font-bold">Logged out</h1>
          <p className="mb-6">
            You have successfully logged out of the dashboard.
          </p>
          <div>
            <Button
              color="primary"
              component={Link}
              disableElevation
              to="/login"
              variant="contained"
            >
              Log In Again
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Logout;
