import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { addAccessTokenInterceptor } from '../../api/axios';

const Members = () => {
  const navigate = useNavigate();
  const [tokenSet, setTokenSet] = useState(false);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        // set accessToken for all api requests
        getAccessTokenSilently()
          .then((token) => {
            addAccessTokenInterceptor(token);
            setTokenSet(true);
          })
          .catch(console.error);
      } else {
        navigate('/login');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return (
    <div className="w-full flex">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>{isAuthenticated && tokenSet && <Outlet />}</>
      )}
    </div>
  );
};

export default Members;
