import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Header = () => {
  return (
    <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
      <Grid item>
        <Typography component="h1" variant="h2">
          Brokers
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          component={RouterLink}
          startIcon={<AddCircleIcon />}
          to="/brokers/manage"
          variant="contained"
        >
          New Broker
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
