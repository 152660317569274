import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FormatDate, FormatPhone, FormatDriversLicence } from 'components';
import { Badge, IconButton, Link as MuiLink } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const List = ({ contacts, list }) => {
  const [listFiltered, setListFiltered] = useState(list);
  let [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search')
    ? searchParams.get('search').toLowerCase()
    : '';

  const showActive = useSelector(
    (state) => state.settings.contacts.filter.showActive
  );

  useEffect(() => {
    if (searchTerm === '') {
      setListFiltered(
        list.filter((key) => {
          if (showActive === true) {
            if (contacts[key].isActive === true) {
              return key;
            }
          } else if (showActive === false) {
            if (contacts[key].isActive === false) {
              return key;
            }
          } else {
            return key;
          }
        })
      );
    } else {
      setListFiltered(
        list.filter((key) => {
          if (
            (contacts[key].fullName &&
              contacts[key].fullName.toLowerCase().includes(searchTerm)) ||
            (contacts[key].nickName &&
              contacts[key].nickName.toLowerCase().includes(searchTerm)) ||
            (contacts[key].company &&
              contacts[key].company.toLowerCase().includes(searchTerm))
          ) {
            return key;
          } else {
            return false;
          }
        })
      );
    }
  }, [searchTerm, contacts, list.length, showActive]);

  return (
    <>
      {listFiltered && listFiltered.length ? (
        <>
          <p className="mb-6 text-slate-500">
            {searchTerm && (
              <>
                search for <strong>{searchTerm}</strong>
              </>
            )}{' '}
            found {listFiltered.length} contacts.
          </p>
          {listFiltered.map((key) => (
            <div
              className={clsx(
                'border',
                'p-4',
                'mb-4',
                'flex',
                'items-center',
                contacts[key]?.isActive === false && 'opacity-50'
              )}
              key={key}
            >
              <div className="pe-2 border-e me-2 border-slate-50 sm:min-w-48">
                <div className="mb-2">
                  {contacts[key].company ? (
                    <>
                      <Link
                        className="font-bold text-md text-blue-600"
                        to={`${key}/policies`}
                      >
                        {contacts[key].company}
                      </Link>
                      <div className="font-bold">{contacts[key].fullName}</div>
                    </>
                  ) : (
                    <Link
                      className="font-bold text-md text-blue-600"
                      to={`${key}/policies`}
                    >
                      {contacts[key].fullName}
                    </Link>
                  )}

                  <p className="text-slate-500">
                    {contacts[key].nickName && <> ({contacts[key].nickName})</>}
                  </p>
                </div>
                <div className="mb-2">
                  <p>
                    <MuiLink
                      component={Link}
                      to={`tel:+1${contacts[key].cellNo}`}
                    >
                      <FormatPhone number={contacts[key].cellNo} />
                    </MuiLink>
                  </p>
                  {contacts[key].address && (
                    <p className="my-2">
                      <a
                        href={`https://maps.google.com/?q=${encodeURI(
                          `${contacts[key].address} ${
                            contacts[key].city || ''
                          } ${contacts[key].province || 'BC'} ${
                            contacts[key].postalCode || ''
                          }`
                        )}`}
                        target="_blank"
                        className="text-blue-800 underline"
                      >
                        {contacts[key].address},
                        <br />
                        {contacts[key].city} {contacts[key].province || 'BC'}
                        {', '}
                        {contacts[key].postalCode}
                      </a>
                    </p>
                  )}
                  {contacts[key].dob && (
                    <p>
                      <span className="text-slate-500 text-sm">D.O.B</span>{' '}
                      <FormatDate date={contacts[key].dob} />
                    </p>
                  )}

                  {contacts[key].driversLicence && (
                    <p>
                      <span className="text-sm text-gray-500">DL#</span>
                      <FormatDriversLicence
                        number={contacts[key].driversLicence}
                      />
                    </p>
                  )}
                </div>
              </div>
              <div>
                <IconButton
                  color="primary"
                  aria-label="View Images"
                  component={Link}
                  to={`${key}/attachments`}
                >
                  <Badge
                    badgeContent={
                      (contacts[key].attachments &&
                        contacts[key].attachments.length) ||
                      0
                    }
                    color="secondary"
                  >
                    <ImageIcon />
                  </Badge>
                </IconButton>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {searchTerm && (
            <>
              Search for <strong>{searchTerm}</strong>
            </>
          )}{' '}
          found 0 contacts
        </>
      )}
    </>
  );
};

List.propTypes = {
  contacts: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  searchTerm: PropTypes.string
};

export default List;
