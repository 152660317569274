import React, { useState } from 'react';
import { Outlet, useParams, Link } from 'react-router-dom';
import { useContacts } from '../../hooks';
import { FormatDate, FormatDriversLicence, FormatPhone } from 'components';
import { IconButton, Menu, MenuItem, Badge } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteContact, activateContact } from 'features/contact/contactSlice';
import { toast } from 'react-toastify';
import clsx from 'clsx';

const MoreCtas = ({ isActive }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contactId } = useParams();

  return (
    <>
      <IconButton
        color="primary"
        aria-label="more options"
        id="contact-more-options"
        aria-controls={open ? 'contact-more-options' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate('update');
          }}
        >
          Update
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (isActive) {
              dispatch(activateContact({ contactId, status: false }));
            } else {
              dispatch(activateContact({ contactId, status: true }));
            }
          }}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            dispatch(deleteContact(contactId))
              .unwrap()
              .then(() => {
                toast.success('Contact deleted successfully');
              })
              .catch((err) => {
                console.error(err);
                toast.error('Failed to delete contact');
              });
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

const Details = ({ contact }) => {
  const {
    id,
    company,
    fullName,
    firstName,
    middleName,
    lastName,
    nickName,
    address,
    city,
    postalCode,
    cellNo,
    email,
    dob,
    driversLicence,
    attachments,
    isActive
  } = contact;

  return (
    <>
      <div className="flex flex-wrap items-start">
        <div>
          {company ? (
            <>
              <h1
                className={clsx(
                  'text-2xl',
                  'me-2',
                  isActive === false && 'text-gray-400'
                )}
              >
                {company}
              </h1>
              <h2 className={clsx(isActive === false && 'text-gray-400')}>
                {firstName} {middleName} {lastName} isActive {isActive}
              </h2>
            </>
          ) : (
            <h1
              className={clsx(
                'text-2xl',
                'me-2',
                isActive === false && 'text-gray-400'
              )}
            >
              {firstName} {middleName} {lastName}
            </h1>
          )}
          {nickName && <p className="text-slate-500">({nickName})</p>}
        </div>
        <div className="flex items-center">
          <div className="me-2">
            <IconButton
              color="primary"
              aria-label="View Images"
              component={Link}
              to={`attachments`}
            >
              <Badge
                badgeContent={(attachments && attachments.length) || 0}
                color="secondary"
              >
                <ImageIcon />
              </Badge>
            </IconButton>
          </div>
          <MoreCtas contactId={id} isActive={isActive} />
        </div>
      </div>
      {/* title&actions*/}

      <div className="md:flex my-3">
        <div className="me-4">
          <p>
            {address}, {city} {postalCode}
          </p>
          {cellNo && (
            <p>
              <span className="text-sm text-slate-500">Cell#</span>{' '}
              <FormatPhone number={cellNo} />
            </p>
          )}
          {email && (
            <p>
              <span className="text-sm text-slate-500">Email:</span> {email}
            </p>
          )}
        </div>
        <div>
          {dob && (
            <p>
              <span className="text-sm text-slate-500">DOB:</span>{' '}
              <FormatDate date={dob} />
            </p>
          )}
          {driversLicence && (
            <p>
              <span className="text-sm text-slate-500">DL#:</span>{' '}
              <FormatDriversLicence number={driversLicence} />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

const Contact = () => {
  const { contacts } = useContacts();
  const { contactId } = useParams();

  return (
    <>
      {contacts && contacts[contactId] && (
        <div className="p-4 border-b border-slate-100">
          <Details contact={contacts[contactId]} />
        </div>
      )}
      <Outlet />
    </>
  );
};

export default Contact;
