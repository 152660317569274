import axios from './axios';

const baseUrl = '/contacts';

export const getContacts = () => axios.get(baseUrl);

export const saveContact = (contact) =>
  contact._id
    ? axios.put(`${baseUrl}/${contact._id}`, contact)
    : axios.post(baseUrl, contact);

export const activateContact = ({ contactId, status }) =>
  axios.get(`${baseUrl}/${contactId}/activate/${!!status ? '1' : '0'}`);

export const deleteContact = (contactId) =>
  axios.delete(`${baseUrl}/${contactId}`);
