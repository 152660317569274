export const pending = (state) => {
  state.status = 'loading';
  state.error = null;
};

export const fulfilled = (state, action) => {
  const {
    firstName,
    lastName,
    middleName,
    nickName,
    address,
    city,
    postalCode,
    cellNo,
    email,
    driversLicence,
    dob,
    driversLicenceExpiry,
    company,
    fullName
  } = action.payload;

  state.status = 'succeeded';
  state.error = null;
  state.data.contacts[action.payload.id] = {
    ...state.data.contacts[action.payload.id],
    firstName,
    lastName,
    middleName,
    nickName,
    address,
    city,
    postalCode,
    cellNo,
    email,
    driversLicence,
    dob,
    driversLicenceExpiry,
    company,
    fullName
  };
};

export const rejected = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};
