import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { TopBar, SideNav } from './components';
import { useNavigate } from 'react-router-dom';
import { Search } from 'components';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { addAccessTokenInterceptor } from '../../api/axios';

const Dashboard = () => {
  const [navOpen, setNavOpen] = useState(true);
  const navigate = useNavigate();
  const topBarRef = useRef(null);
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [tokenSet, setTokenSet] = useState(false);
  const showSearch = useSelector((state) => state.settings.general.showSearch);
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
    useAuth0();

  useEffect(() => {
    // Top bar and window width related logic
    setTopBarHeight(topBarRef.current.clientHeight);

    if (window.innerWidth < 1024) {
      setNavOpen(false);
    }

    if (!isLoading) {
      if (
        isAuthenticated &&
        user?.[`${process.env.REACT_APP_AUTH0_IDENTIFIER}/userId`]
      ) {
        // set accessToken for all api requests
        getAccessTokenSilently()
          .then((token) => {
            addAccessTokenInterceptor(token);
            setTokenSet(true);
          })
          .catch(console.error);
      } else {
        navigate('/login');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return (
    <>
      <TopBar setNavOpen={setNavOpen} navOpen={navOpen} topBarRef={topBarRef} />
      <div
        className="p2 bg-slate-50"
        style={{
          position: 'fixed',
          width: navOpen ? '16rem' : '3rem',
          top: `${topBarHeight}px`,
          bottom: 0,
          left: 0,
          zIndex: 1
        }}
      >
        <SideNav setNavOpen={setNavOpen} navOpen={navOpen} />
      </div>
      <div
        className="w-full flex"
        style={{ marginTop: `${topBarHeight}px`, position: 'relative' }}
      >
        <div
          className="flex-1"
          style={{ marginLeft: navOpen ? `16rem` : '3rem' }}
        >
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              {isAuthenticated && tokenSet && (
                <>
                  {showSearch && <Search />}
                  <Outlet />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
