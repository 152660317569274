import axios from './axios';

const baseUrl = '/policies';

export const getPolicies = (contactId) =>
  axios.get(baseUrl, { params: { contactId } });

export const getPoliciesSearch = (params) => axios.get(baseUrl, { params });

export const savePolicy = (policy) => axios.post(baseUrl, policy);

export const fixPolicy = ({ id, primaryContact, transactionType, ...data }) =>
  axios.put(`${baseUrl}/${id}`, data);

export const movePolicy = ({ policyId, contactId }) =>
  axios.put(`${baseUrl}/move/${policyId}`, { contactId });

// Cancel Policy; sets isActive to false, but also gives an option to update the notes
export const cancelPolicy = ({ policyId, notes }) =>
  axios.put(`${baseUrl}/cancel/${policyId}`, { notes });

// Expire simply marks the policy isActive:false
export const expirePolicy = ({ policyId }) =>
  axios.get(`${baseUrl}/expire/${policyId}`);

export const deletePolicy = ({ policyId }) =>
  axios.delete(`${baseUrl}/${policyId}`);
