import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/Spinner/Spinner';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { setReportFilter } from 'features/settings/settingsSlice';
import { parseISO, format } from 'date-fns';
import { getPoliciesSearch } from 'api/policiesApi';
import { useContacts } from 'hooks';
import { PolicyItem } from 'components';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useBrokers } from 'hooks';

const Expiring = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, getValues } = useForm();
  const { filter } = useSelector((state) => state.settings.report);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('idle');
  const [result, setResult] = useState(null);
  const { contacts } = useContacts();
  const [reportCSV, setReportCSV] = useState([]);
  const { brokers, list: brokersList } = useBrokers();

  const loadResult = (from, upTo, brokerId) => {
    setLoading(true);
    getPoliciesSearch({
      filterBy: 'coverageFrom',
      filterFrom: from,
      filterUpTo: upTo,
      broker: brokerId
    })
      .then((result) => {
        setResult(result);
        setStatus('succeeded');
      })
      .catch((error) => {
        console.error(error);
        setStatus('failed');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (status === 'idle') {
      loadResult(filter.from, filter.upTo, filter.brokerId);
    }
  }, [status, filter.from, filter.upTo]);

  useEffect(() => {
    // let's make report here
    if (contacts && result && result.length) {
      setReportCSV([
        [
          'Contact',
          'Coverage From',
          'Coverage Upto',
          'Type',
          'Insurer',
          'Insured'
        ],
        ...result
          .sort((a, b) => {
            if (a.coverageFrom < b.coverageFrom) {
              return -1;
            } else if (a.coverageFrom > b.coverageFrom) {
              return 1;
            }

            return 0;
          })
          .map(
            ({
              primaryContact,
              coverageFrom,
              coverageUpTo,
              policyType,
              insurer,
              make,
              model,
              year,
              plateNo,
              address,
              insured
            }) => {
              const fullName = `${contacts[primaryContact]?.firstName || ''} ${
                contacts[primaryContact]?.middleName || ''
              } ${contacts[primaryContact]?.lastName || ''}`;

              return [
                contacts[primaryContact]?.company
                  ? contacts[primaryContact]?.company
                  : fullName,
                coverageFrom
                  ? format(parseISO(coverageFrom), 'dd-MMM-yyyy')
                  : '',
                coverageUpTo
                  ? format(parseISO(coverageUpTo), 'dd-MMM-yyyy')
                  : '',
                policyType,
                insurer,
                policyType === 'auto' || policyType === 'storage'
                  ? `${make || ''} ${model || ''} ${year || ''} ${
                      plateNo || ''
                    }`
                  : policyType === 'home'
                  ? address
                  : insured
              ];
            }
          )
      ]);
    }
  }, [contacts, result && result.length]);

  const handleFormSubmit = ({ from, upTo, brokerId }) => {
    // save the new dates in the state (reportFilter)
    dispatch(
      setReportFilter({
        from: from.toISOString(),
        upTo: upTo.toISOString(),
        brokerId
      })
    );
    // load the new result
    loadResult(from, upTo, brokerId);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6">Report</h1>

      <form onSubmit={handleSubmit(handleFormSubmit)} className="mb-6">
        <div className="flex flex-wrap items-center">
          <div className="me-1 mb-4 w-full sm:w-40">
            <Controller
              name="brokerId"
              control={control}
              defaultValue={filter.brokerId || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Broker"
                  fullWidth
                  SelectProps={{ native: true }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  <option key="0" value="">
                    select
                  </option>
                  {brokersList ? (
                    <>
                      {brokersList.map((key) => {
                        return (
                          <option key={key} value={key}>
                            {brokers[key].brokerName}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </TextField>
              )}
            />
          </div>

          <div className="me-1 mb-4 w-32 sm:w-40">
            <Controller
              name="from"
              control={control}
              defaultValue={filter.from ? parseISO(filter.from) : ''}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="From"
                  //renderInput={(params) => <TextField {...params} />}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              )}
            />
          </div>

          <div className="me-1 mb-4 w-32 sm:w-40">
            <Controller
              name="upTo"
              control={control}
              defaultValue={filter.upTo ? parseISO(filter.upTo) : ''}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Upto"
                  //renderInput={(params) => <TextField {...params} />}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
              )}
            />
          </div>

          <div className="mb-4 me-2">
            <Button type="submit" variant="contained" color="primary">
              Filter
            </Button>
          </div>

          {contacts && result && result.length && (
            <div className="mb-4">
              <CSVLink
                data={reportCSV}
                filename={`report-${
                  getValues('from')
                    ? format(getValues('from'), 'dd-MMM-yyyy')
                    : ''
                }-to-${
                  getValues('upTo')
                    ? format(getValues('upTo'), 'dd-MMM-yyyy')
                    : ''
                }.csv`}
              >
                <Button variant="outlined" color="primary">
                  Download
                </Button>
              </CSVLink>
            </div>
          )}
        </div>
      </form>

      {loading ? (
        <Spinner />
      ) : (
        <>
          {result && (
            <>
              {result.length ? (
                <>
                  {result
                    .sort((a, b) => {
                      if (a.coverageFrom < b.coverageFrom) {
                        return -1;
                      } else if (a.coverageFrom > b.coverageFrom) {
                        return 1;
                      }

                      return 0;
                    })
                    .map(({ id, primaryContact, ...rest }) => (
                      <div className="border rounded p-4 mb-4" key={id}>
                        {contacts?.[primaryContact]?.company ? (
                          <>
                            <p className="font-bold text-blue-500">
                              <Link
                                to={`/dashboard/contacts/${primaryContact}/policies`}
                              >
                                {contacts?.[primaryContact]?.company}
                              </Link>
                            </p>
                            <p className="mb-2">{`${
                              contacts?.[primaryContact]?.firstName
                            } ${contacts?.[primaryContact]?.middleName || ''} ${
                              contacts?.[primaryContact]?.lastName
                            }`}</p>
                          </>
                        ) : (
                          <p className="mb-2 font-bold text-blue-500">
                            <Link
                              to={`/dashboard/contacts/${primaryContact}/policies`}
                            >{`${contacts?.[primaryContact]?.firstName} ${
                              contacts?.[primaryContact]?.middleName || ''
                            } ${contacts?.[primaryContact]?.lastName}`}</Link>
                          </p>
                        )}
                        <PolicyItem policy={{ id, primaryContact, ...rest }} />
                      </div>
                    ))}
                </>
              ) : (
                <p>0 result</p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Expiring;
