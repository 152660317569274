export const pending = (state) => {
  state.status = 'loading';
  state.error = null;
};

export const fulfilled = (state, action) => {
  const { isActive } = action.payload;

  state.status = 'succeeded';
  state.error = null;
  state.data.contacts[action.payload.id] = {
    ...state.data.contacts[action.payload.id],
    isActive
  };
};

export const rejected = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};
