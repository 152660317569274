import axios from './axios';

const baseUrl = '/attachments';

// import { handleResponse, handleError, authHeader } from './apiUtils';
// const baseUrl = process.env.REACT_APP_API_URL + '/attachments';

export const getAttachments = ({ contactId, policyId }) =>
  axios.get(baseUrl, {
    params: contactId ? { contactId } : { policyId }
  });

export const getAttachmentThumb = (id) =>
  axios.get(`${baseUrl}/thumb/${id}`, {
    responseType: 'blob' // This ensures Axios treats the response as a binary blob
  });

export const getAttachmentImage = (id) =>
  axios.get(`${baseUrl}/image/${id}`, {
    responseType: 'blob' // This ensures Axios treats the response as a binary blob
  });

export const postAttachment = (data) =>
  axios.post(baseUrl, data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  });

export const deleteAttachment = ({ id, contactId, policyId }) =>
  axios.delete(`${baseUrl}/${id}`, {
    params: contactId ? { contactId: contactId } : { policyId: policyId }
  });

export const moveToContact = ({ id, policyId }) => {
  return axios.get(`${baseUrl}/move-to-contact/${id}`, {
    params: { policyId }
  });
};
