import React from 'react';
import PropTypes from 'prop-types';

const FormatDriversLicence = ({ number }) => {
  if (!number) {
    return <></>;
  } else {
    return number.slice(0, 3) + ' ' + number.slice(3);
  }
};

FormatDriversLicence.propTypes = {
  value: PropTypes.string
};

export default FormatDriversLicence;
