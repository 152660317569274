import { createSlice } from '@reduxjs/toolkit';
import {
  addDays,
  subDays,
  startOfDay,
  startOfMonth,
  endOfMonth
} from 'date-fns';

const initialState = {
  general: {
    showSearch: false
  },
  contacts: {
    filter: {
      showActive: true
    }
  },
  policies: {
    filter: {
      showActive: null
    }
  },
  expiring: {
    filter: {
      from: startOfDay(subDays(new Date(), 90)).toISOString(),
      upTo: startOfDay(addDays(new Date(), 30)).toISOString()
    }
  },
  report: {
    filter: {
      brokerId: '',
      from: startOfMonth(subDays(new Date(), 90)).toISOString(),
      upTo: endOfMonth(subDays(new Date(), 30)).toISOString()
    }
  }
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setShowSearch(state, action) {
      state.general.showSearch = action.payload;
    },
    setContactsFilter(state, action) {
      state.contacts.filter.showActive = action.payload;
    },
    setPoliciesFilter(state, action) {
      state.policies.filter.showActive = action.payload;
    },
    setExpiringFilter(state, action) {
      const { from, upTo } = action.payload;
      state.expiring.filter.from = from;
      state.expiring.filter.upTo = upTo;
    },
    setReportFilter(state, action) {
      const { from, upTo, brokerId } = action.payload;
      state.report.filter.from = from;
      state.report.filter.upTo = upTo;
      state.report.filter.brokerId = brokerId;
    }
  }
});

export const {
  setShowSearch,
  setContactsFilter,
  setPoliciesFilter,
  setExpiringFilter,
  setReportFilter
} = settingsSlice.actions;
export default settingsSlice.reducer;
