import React from 'react';
import { Header, List } from './components';
import { Spinner } from 'components';
import { useContacts } from '../../hooks';
import { Alert } from '@mui/material';

const Contacts = () => {
  const { status, contacts, error, list } = useContacts();

  return (
    <div className="p-4">
      <Header />
      {error && (
        <div className="my-3">
          <Alert severity="warning">{error}</Alert>
        </div>
      )}

      {status === 'loading' ? (
        <Spinner />
      ) : (
        <>
          {list && list.length ? (
            <List contacts={contacts} list={list} />
          ) : (
            <p>0 contacts</p>
          )}
        </>
      )}
    </div>
  );
};

export default Contacts;
