import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as userApi from '../../api/userApi';

export const loginUser = createAsyncThunk(
  'users/loginUser',
  async (params) => await userApi.loginUser(params)
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    status: 'idle',
    error: null
  },
  reducers: {
    logoutUser: () => {
      localStorage.removeItem('user');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { logoutUser } = userSlice.actions;

export default userSlice.reducer;
