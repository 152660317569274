import { normalize } from 'normalizr';
import policySchema from '../policySchema';

export const pending = (state, { meta: { arg: contactId } }) => {
  if (state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].policies.status = 'loading';
    state.data.contacts[contactId].policies.error = null;
  }
};

export const fulfilled = (state, { meta: { arg: contactId }, payload }) => {
  if (state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].policies.status = 'succeeded';
    state.data.contacts[contactId].policies.error = null;

    if (payload.length) {
      const normalizedData = normalize(payload, [policySchema]);

      state.data.policies = {
        ...state.data.policies,
        ...normalizedData.entities.policies
      };
      state.data.contacts[contactId].policies.list = normalizedData.result;
    }
  }
};

export const rejected = (state, { meta: { arg: contactId }, error }) => {
  if (state?.data?.contacts?.[contactId]) {
    state.data.contacts[contactId].policies.status = 'failed';
    state.data.contacts[contactId].policies.error = error.message;
  }
};
