import React, { useEffect } from 'react';
import { Header, List } from './components';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import { Dashboard } from '../../layouts';

const Brokers = ({ history, loadBrokers, loading, brokerReducer }) => {
  useEffect(() => {
    if (brokerReducer.list === null) {
      loadBrokers().catch((error) => {
        toast.error('loading brokers failed' + error);
      });
    }
  }, [brokerReducer, loadBrokers]);

  return (
    <Dashboard>
      <Header />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {brokerReducer.list !== null && brokerReducer.list.length ? (
            <>
              <List
                brokers={brokerReducer.brokers}
                history={history}
                list={brokerReducer.list}
              />
            </>
          ) : (
            <p>0 brokers</p>
          )}
        </>
      )}
    </Dashboard>
  );
};

export default Brokers;
