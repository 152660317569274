import React from 'react';
import { Outlet } from 'react-router-dom';

const Auth = () => {
  return (
    <div className="layout layout-auth flex flex-col min-h-screen w-full justify-center items-center bg-gray-50">
      <Outlet />
    </div>
  );
};

export default Auth;
