import React, { useState } from 'react';
import { deletePolicy } from 'features/contact/contactSlice';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const DeletePolicy = ({ policyId, contactId, isDisabled }) => {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      {showDeleteWarning ? (
        <>
          <h5 className="font-bold">
            Are you sure you want to delete this policy?
          </h5>
          <div className="my-2 flex">
            <div className="me-2">
              <Button
                variant="outlined"
                onClick={() => setShowDeleteWarning(false)}
              >
                Cancel
              </Button>
            </div>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                console.log('deleting', {
                  policyId,
                  contactId
                });
                dispatch(
                  deletePolicy({
                    policyId,
                    contactId
                  })
                )
                  .unwrap()
                  .then(() => toast.success('Policy successfully deleted.'))
                  .catch((error) => {
                    console.error(error);
                    toast.error('There was an error deleting policy.');
                  });
              }}
            >
              Delete
            </Button>
          </div>
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setShowDeleteWarning(true)}
            disabled={isDisabled}
          >
            Delete
          </Button>
        </>
      )}
      <p className="mt-2 text-sm text-red-500">
        <strong>Not Recommended</strong>: it will erase the history
      </p>
      <p className="text-sm text-slate-500">
        this will permanently delete the policy and could also effect your past
        reports.
      </p>
      <p className="py-2 text-sm text-slate-500">
        To delete a policy you must first delete all{' '}
        <span className="text-blue-500">
          <Link
            to={`/dashboard/contacts/${contactId}/policies/${policyId}/attachments`}
          >
            policy attachments
          </Link>
        </span>
        .
      </p>
    </>
  );
};

export default DeletePolicy;
