import * as Yup from 'yup';

const policySchema = Yup.object().shape({
  broker: Yup.string().required('Broker is required'),
  createdAt: Yup.date()
    .required('Created On date is required')
    .typeError('Enter a valid date'),
  coverageFrom: Yup.date().required('Effective from date is required'),
  coverageUpTo: Yup.date()
    .required('Valid upto date is required')
    .when(
      'coverageFrom',
      (coverageFrom, schema) => coverageFrom && schema.min(coverageFrom)
    ),

  make: Yup.string().when('policyType', {
    is: (val) => val === 'auto' || val === 'storage',
    then: Yup.string().required('Make is required')
  }),
  model: Yup.string().when('policyType', {
    is: (val) => val === 'auto' || val === 'storage',
    then: Yup.string().required('Model is required')
  }),
  year: Yup.string().when('policyType', {
    is: (val) => val === 'auto' || val === 'storage',
    then: Yup.string().required('Year is required')
  }),

  plateNo: Yup.string().when('policyType', {
    is: (val) => val === 'auto',
    then: Yup.string().required('Plate# is required')
  }),

  address: Yup.string().when('policyType', {
    is: (val) => val === 'home',
    then: Yup.string().required('Address is required')
  }),

  insurer: Yup.string().when('policyType', {
    is: (val) => val === 'home' || val === 'visitor',
    then: Yup.string().required('Insurer is required')
  }),

  insured: Yup.string().when('policyType', {
    is: (val) => val === 'visitor',
    then: Yup.string().required('Insured is required')
  })
});

export default policySchema;
