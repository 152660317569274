import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cancelPolicy } from 'features/contact/contactSlice';
import * as Yup from 'yup';
import { Button, TextField } from '@mui/material';
import { format } from 'date-fns';

const PolicyCancel = () => {
  const dispatch = useDispatch();
  const { contactId, policyId } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const policies = useSelector((state) => state.contacts.data.policies);
  const navigate = useNavigate();
  const policySchema = Yup.object().shape({
    notes: Yup.string()
  });

  useEffect(() => {
    if (policies[policyId]) {
      const autoNote = `Cancelling on ${format(new Date(), 'MMM d, yyyy')}`;
      setInitialValues({
        notes: policies[policyId].notes
          ? `${autoNote}\nPrevNote: ${policies[policyId].notes}`
          : autoNote
      });
    } else {
      //if not present go back to policies;
      navigate(`/dashboard/contacts/${contactId}/policies`);
    }
  }, [policyId]);

  return (
    <div className="p-4">
      <div className="flex items-center mb-6">
        <div className="me-2">
          <IconButton
            component={Link}
            to={`/dashboard/contacts/${contactId}/policies`}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <h1 className="text-2xl font-bold">Cancel policy</h1>
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          // also add the logic to fix the policy
          dispatch(
            cancelPolicy({
              policyId,
              primaryContact: contactId,
              ...values
            })
          )
            .unwrap()
            .then(() => {
              setSubmitting(false);
              toast.success('Policy successfully canceled.');
              navigate(`/dashboard/contacts/${contactId}/policies`);
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
              toast.error('There was an error cancelling the new policy');
            });
        }}
        validationSchema={policySchema}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => {
          return (
            <Form>
              {/*<p>{JSON.stringify(values)}</p>*/}
              <div className="w-full md:w-80 lg:w-96 mb-6">
                <TextField
                  defaultValue={values.notes}
                  error={errors.notes && touched.notes}
                  helperText={errors.notes && touched.notes && errors.notes}
                  label="Notes"
                  name="notes"
                  multiline
                  minRows={2}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
                <p className="my-2 text-sm text-slate-500">
                  <span className="text-green-500">HIGHLY RECOMMENDED:</span>{' '}
                  Keep notes to recall what happened during the transaction.
                  Anything that could help you in the future.
                </p>
              </div>

              <Button
                color="primary"
                disabled={isSubmitting}
                disableElevation
                type="submit"
                variant="contained"
              >
                {isSubmitting ? 'Saving' : 'Submit'}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PolicyCancel;
