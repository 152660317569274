import axios from './axios';

// import { handleResponse, handleError, authHeader } from './apiUtils';
// const baseUrl = process.env.REACT_APP_API_URL + '/brokers';
const baseUrl = '/brokers';

export const getBrokers = () => axios.get(baseUrl);

export const saveBroker = (broker) =>
  broker._id
    ? axios.put(`${baseUrl}/${broker._id}`, broker)
    : axios.post(baseUrl, broker);
