import React, { useState } from 'react';
import { Button, Modal, TextField, Autocomplete } from '@mui/material';
import { useContacts } from 'hooks';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { movePolicy } from 'features/contact/contactSlice';
import { toast } from 'react-toastify';

// contactId is the current contactID;
// its required to update the state in reducers
const MovePolicy = ({ contactId: prevContactId, policyId }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { contacts, list } = useContacts();
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const handleFormSubmit = ({ contactId }) => {
    dispatch(
      movePolicy({
        policyId,
        contactId,
        prevContactId
      })
    )
      .unwrap()
      .then((policy) => {
        toast.success('Policy successfully moved');
      })
      .catch((err) => {
        console.error(err);
        toast.error('Failed to move Policy');
      });
  };

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Move
      </Button>
      <p className="mt-2 mb-6 md:mb-0 text-sm text-slate-500">
        If you have entered the policy under incorrect contact, use this option
        to move the policy to another contact.
      </p>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-96">
          <div className="bg-white p-4 rounded">
            <h2 className="text-xl font-bold">Select Contact</h2>
            <p className="text-sm text-slate-500 mt-2 mb-6">
              select a contact to <strong>move the policy</strong> under their
              account.
            </p>
            {list && contacts && (
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="mb-4">
                  <Controller
                    name="contactId"
                    control={control}
                    defaultValue={''}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        onChange={(e, value) => onChange(value)}
                        disablePortal
                        id="contact-id"
                        options={list}
                        getOptionLabel={(option) =>
                          `${contacts[option]?.firstName} ${
                            contacts[option]?.middleName || ''
                          } ${contacts[option]?.lastName}` || '-'
                        }
                        getOptionKey={(option) => option}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label="Search Contacts"
                          />
                        )}
                      />
                    )}
                  />
                </div>
                <Button variant="contained" type="submit">
                  Move Policy
                </Button>
              </form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MovePolicy;
