export const pending = (state, action) => {
  //return state;
};

export const fulfilled = (state, action) => {
  //return state;
};

export const rejected = (state, action) => {
  //return state;
};
