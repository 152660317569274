export default {
  '@global': {
    html: {
      height: '100%'
    },
    body: {
      height: '100%'
    },
    '#root': {
      height: '100%'
    },
    '.textCenter': {
      textAlign: 'center'
    },
    '.formGroup': {
      marginBottom: '1.4rem'
    },
    '.rich-editor .rdw-editor-main': {
      //backgroundColor: '#fff'
    },
    '.public-DraftEditorPlaceholder-root': {
      padding: '1rem'
    },
    '.rich-editor .DraftEditor-editorContainer': {
      padding: '1rem',
      border: 'solid 1px rgba(0,0,0,0.15)'
    },
    '.draft-editor-wrapper': {
      border: '1px solid #ccc'
    }
  }
};
