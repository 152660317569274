import React, { useState } from 'react';
import config from './navigationConfig';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const Children = ({ config, setNavOpen }) => (
  <>
    {config.map((item) => (
      <li className="ps-11 border-b border-blue-700" key={item.title}>
        <Link
          to={item.href}
          className="block py-1"
          onClick={() => {
            if (window.innerWidth < 1024) {
              setNavOpen(false);
            }
          }}
        >
          {item.title}
        </Link>
      </li>
    ))}
  </>
);

const Node = ({ item, setNavOpen, navOpen }) => {
  return (
    <li className="sidenav-item">
      <Link
        to={item.href}
        className="flex flex-auto items-center w-full px-4 py-2"
        onClick={() => {
          if (window.innerWidth < 1024) {
            setNavOpen(false);
          }
        }}
      >
        {item.icon && <item.icon />}
        <span className={clsx('ml-3', !navOpen && 'hidden')}>{item.title}</span>
      </Link>
      {item.children && (
        <ul>
          <Children config={item.children} setNavOpen={setNavOpen} />
        </ul>
      )}
    </li>
  );
};

const SideNav = ({ toggleSideNav, navOpen, setNavOpen }) => {
  return (
    <ul className="mt-6">
      {config.map((item) => (
        <Node
          item={item}
          toggleSideNav={toggleSideNav}
          key={item.title}
          navOpen={navOpen}
          setNavOpen={setNavOpen}
        />
      ))}
    </ul>
  );
};

export default SideNav;
