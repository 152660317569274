import { configureStore, combineReducers } from '@reduxjs/toolkit';
import users from 'features/user/userSlice';
import contacts from 'features/contact/contactSlice';
import brokers from 'features/broker/brokerSlice';
import settings from 'features/settings/settingsSlice';

const combinedReducer = combineReducers({
  users,
  contacts,
  brokers,
  settings
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer
});
