import { schema } from 'normalizr';

const contactProcessStrategy = (value) => {
  return {
    ...value,
    attachmentsStatus: 'succeeded',
    attachmentsError: null,
    policies: {
      list: [],
      status: 'idle',
      error: null
    }
  };
};

const contactsSchema = new schema.Entity(
  'contacts',
  {},
  { idAttribute: '_id', processStrategy: contactProcessStrategy }
);

export default contactsSchema;
